import {theme} from 'antd'

const darkTheme = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: '#340156',
  },
  components: {
    Layout: {
      headerBg: '#001529FF',
      colorBgLayout: '#001529FF'
    },
  },
}
export default darkTheme
