import {configureStore} from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

if (process.env.ENVIRONMENT === 'DEVELOP' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.ENVIRONMENT === 'DEVELOP',
})

export default store
