import React, {useState} from 'react';
import {ConfigProvider} from 'antd';
import {themeColorStorageKey} from './constants/defaultValues'
import lightTheme from "./assets/theme/lightTheme";
import darkTheme from "./assets/theme/darkTheme";
import {Route, Routes} from "react-router-dom";
import {routePath} from "./constants/route";
import moment from "moment";

require('dayjs/locale/th')

moment.locale('th')

export const ThemeValueContext = React.createContext(null)

const ViewMain = React.lazy(() => import(/* webpackChunkName: "views-app" */ './feature'))
const ViewLogin = React.lazy(() => import(/* webpackChunkName: "views-login" */ './feature/login'))
const HttpNotFound = React.lazy(() => import(/* webpackChunkName: "views-404" */ './feature/error/HttpNotFound'))
const EmployeeInfoPage = React.lazy(() => import(/* webpackChunkName: "views-employeeInfo" */ './feature/employeeInfo'))
const DisciplinePage = React.lazy(() => import(/* webpackChunkName: "views-discipline" */ './feature/discipline'))
const DisciplineReportPage = React.lazy(() => import(/* webpackChunkName: "views-discipline-report" */ './feature/disciplineReport'))
const TimeReportPage = React.lazy(() => import(/* webpackChunkName: "views-time-report" */ './feature/timeReport'))
const PersonalDetailPage = React.lazy(() => import(/* webpackChunkName: "views-discipline-detail" */ './feature/discipline/PersonalDetail'))
const EmployeeAddressPage = React.lazy(() => import(/* webpackChunkName: "views-employee-address" */ './feature/employeeAddress'))
const EmployeeFamilyPage = React.lazy(() => import(/* webpackChunkName: "views-employee-family" */ './feature/employeeFamily'))
const EmployeeEmergencyContactPage = React.lazy(() => import(/* webpackChunkName: "views-employee-emergency-contact" */ './feature/employeeEmergencyContact'))
const SettingsPage = React.lazy(() => import(/* webpackChunkName: "views-settings" */ './feature/settings'))
const EmployeeReportPage = React.lazy(() => import(/* webpackChunkName: "views-employee-report" */ './feature/employeeReport'))
const EmployeeInfoDetailPage = React.lazy(() => import(/* webpackChunkName: "views-employee-info-detail" */ './feature/employeeInfo/EmployeeInfoDetail'))
const Callback = React.lazy(() => import(/* webpackChunkName: "views-callback" */ './feature/Callback'))

const App = () => {
  const [themeValue, setThemeValue] = useState(localStorage.getItem(themeColorStorageKey) ? localStorage.getItem(themeColorStorageKey) : 'light')

  return (
    <ThemeValueContext.Provider value={{themeValue, setThemeValue}}>
      <ConfigProvider theme={themeValue === 'light' ? {...lightTheme} : {...darkTheme}}>
        <Routes>
          <Route caseSensitive path={routePath.callback} element={<Callback />} />
          <Route caseSensitive path={routePath.login} element={<ViewLogin />} />
          <Route caseSensitive path={routePath.home} element={<ViewMain />}>
            <Route path={routePath.employeeInfo} element={<EmployeeInfoPage />} />
            <Route path={routePath.employeeInfoDetail} element={<EmployeeInfoDetailPage />} />
            <Route path={routePath.discipline} element={<DisciplinePage />} />
            <Route path={routePath.disciplineReport} element={<DisciplineReportPage />} />
            <Route path={routePath.disciplineDetail} element={<PersonalDetailPage />} />
            <Route path={routePath.timeReport} element={<TimeReportPage />} />
            <Route path={routePath.employeeAddress} element={<EmployeeAddressPage />} />
            <Route path={routePath.employeeFamily} element={<EmployeeFamilyPage />} />
            <Route path={routePath.employeeEmergencyContact} element={<EmployeeEmergencyContactPage />} />
            <Route path={routePath.employeeDisciplineReport} element={<EmployeeReportPage />} />
            <Route path={routePath.settings} element={<SettingsPage />} />
            <Route path={routePath.notFound} element={<HttpNotFound />} />
          </Route>
          <Route caseSensitive path={routePath.notFound} element={<HttpNotFound />} />
        </Routes>
      </ConfigProvider>
    </ThemeValueContext.Provider>
  );
}

export default App;
