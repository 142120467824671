import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  showFullSidebar: false,
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState: initialState,
  reducers: {
    toggleShowFullSidebar: (state) => {
      state.showFullSidebar = !state.showFullSidebar
    },
    resetMenu: state => initialState
  },
})

export const {
  toggleShowFullSidebar,
  resetMenu
} = menuSlice.actions

export const selectShowFullSidebar = state => state.menu.showFullSidebar

export default menuSlice.reducer
