import {combineReducers} from '@reduxjs/toolkit'
import menuSlice from './slice/menuSlice'
import empEmergencyContactSlice from "./slice/empEmergencyContactSlice";
import globalSlice from "./slice/globalSlice";
import userInfoSlice from "./slice/userInfoSlice";

const rootReducer = combineReducers({
  menu: menuSlice,
  global: globalSlice,
  empEmergencyContact: empEmergencyContactSlice,
  userInfo: userInfoSlice
})

export default rootReducer
