import {theme} from 'antd'

const lightTheme = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorBgLayout: '#ffffff',
    colorPrimary: '#340156',
  },
}
export default lightTheme
