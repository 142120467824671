const routePath = {
  login : '/login',
  home: '/',
  employeeInfo: '/employee-info',
  employeeInfoDetail: '/employee-info/detail/:employeeNo',
  discipline: '/discipline',
  disciplineReport: '/discipline-report',
  disciplineDetail: '/discipline/detail/:empDiscpId',
  timeReport: '/time-report',
  employeeAddress: '/employee-address',
  employeeFamily: '/employee-family',
  employeeEmergencyContact: '/employee-emergency-contact',
  employeeDisciplineReport: '/employee-discipline-report',
  settings: '/settings',
  notFound: '*',
  callback: '/callback',
  notFoundContent: '/404',
}

export {routePath}
