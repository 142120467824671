import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
  },
})

export const selectIsLoading = (state) => state.global.isLoading

const {actions, reducer} = globalSlice

export const {
  startLoading,
  stopLoading
} = actions
export default reducer
