import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  empEmergencyContactDataTable: null,
}

export const empEmergencyContactSlice = createSlice({
  name: 'empEmergencyContact',
  initialState: initialState,
  reducers: {
    setEmpEmergencyContactDataTable: (state, action) => {
      state.empEmergencyContactDataTable = action.payload
    },
    resetEmpEmergencyContact: state => initialState
  },
})


export const {
  setEmpEmergencyContactDataTable,
  resetEmpEmergencyContact
} = empEmergencyContactSlice.actions

export const selectEmpEmergencyContactDataTable = state => state.empEmergencyContact.empEmergencyContactDataTable

export default empEmergencyContactSlice.reducer
