import {createSlice} from '@reduxjs/toolkit'
import {deleteUserCookie} from "../../helper/AuthUtils";

const initialState = {
  userInfo: {},
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    resetUser: state => {
      state = initialState
      deleteUserCookie()
    }
  }
})

export const {
  setUserInfo,
  resetUserInfo
} = userInfoSlice.actions

export const selectUserInfo = state => state.userInfo.userInfo

export default userInfoSlice.reducer
