import Cookies from 'js-cookie'

const getTokenFromCookie = () => {
  return Cookies.get('Authorization-Token')
}

const saveTokenToCookie = (token, expires_in) => {
  Cookies.set('Authorization-Token', token, {expires: expires_in})
}

const deleteTokenFromCookie = () => {
  Cookies.remove('Authorization-Token')
}

const saveFirstname = (firstname) => {
  Cookies.set('firstname', firstname)
}

const saveLastname = (lastname) => {
  Cookies.set('lastname', lastname)
}

const saveUserCookie = (firstname, lastname) => {
  saveFirstname(firstname)
  saveLastname(lastname)
}

const deleteUserCookie = () => {
  deleteTokenFromCookie()
  // deleteFirstname()
  // deleteLastname()
  // deleteRole()
}

export {
  getTokenFromCookie,
  saveTokenToCookie,
  deleteTokenFromCookie,
  deleteUserCookie,
  saveUserCookie
}
